import { ReactNode, useRef } from 'react'

type Props = {
	mainContent?: ReactNode
	secondaryContent?: ReactNode
	hasFeaturedSection?: boolean
	hasFeaturedBackgroundColor?: boolean
}

export function NavigationGridContainer({
	mainContent,
	secondaryContent,
	hasFeaturedBackgroundColor,
	hasFeaturedSection
}: Props) {
	const gridContainerRef = useRef<HTMLDivElement | null>(null)
	const sectionContainerRef = useRef<HTMLDivElement | null>(null)
	const mainContentRef = useRef<HTMLDivElement | null>(null)

	return (
		<div
			className="w-full flex justify-center relative px-6 md:px-8 xl:px-16 xl-plus:px-0"
			data-testid="navigation-grid-container"
			ref={gridContainerRef}
			style={{
				background: `linear-gradient(to right, #ffffff 50%, ${
					hasFeaturedSection && hasFeaturedBackgroundColor ? '#edeff3' : '#ffffff'
				} 50%)`
			}}
		>
			<div
				className={`w-full max-w-[1440px] grid z-20 section-container ${
					hasFeaturedSection ? 'has-featured-section' : ''
				}`}
				ref={sectionContainerRef}
			>
				<div className="bg-white col-span-3" ref={mainContentRef}>
					{mainContent}
				</div>
				{hasFeaturedSection && (
					<div
						className="col-span-1 min-w-[330px]"
						style={{
							backgroundColor:
								hasFeaturedSection && hasFeaturedBackgroundColor ? '#edeff3' : '#ffffff'
						}}
					>
						{secondaryContent}
					</div>
				)}
			</div>
		</div>
	)
}
